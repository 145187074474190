import 'swiper/swiper-bundle.css';

import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { DImage } from '@common/components/common/image';
import Select from '@common/components/common/select';
import { useHeaderNotice } from '@common/components/hook/header/use-header-notice';
import env from '@common/constants/env';
import { LANGUAGE_OPTIONS } from '@common/constants/language';
import { ROUTE_MAP } from '@common/constants/route-map';
import { isEmpty } from 'lodash-es';
import Link from 'next/link';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { IMG_LIVING_LOGO } from '@/resources/images';

interface Props {
  className?: string;
  showUser?: boolean;
}

export const noticeHeaderId = 'notice-header';

export const HeaderNotice: React.FC<Props> = ({ className = '' }) => {
  const { noticeList, language, isRNWebViewState, selectLanguage } = useHeaderNotice();

  return (
    <div id={noticeHeaderId} className="w-full bg-black-1">
      <div className={classNames('container', 'w-full mx-auto h-8.5', 'pc:h-10', className)}>
        <div className={classNames('h-full flex items-center justify-between relative')}>
          {!isEmpty(noticeList) ? (
            <Swiper
              className="w-full h-full flex-1"
              direction="vertical"
              autoplay
              slidesPerView={1}
              loop
            >
              {noticeList.map((item, index) => (
                <SwiperSlide
                  className="flex items-center w-full h-full overflow-hidden"
                  key={index}
                >
                  <a
                    className={classNames(
                      'text-white text-xs cursor-pointer',
                      'line-clamp-1',
                      'pc:line-clamp-none'
                    )}
                    href={item.url}
                  >
                    {item.title}
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="w-full h-full flex-1"></div>
          )}
          <div className={classNames('items-center h-full', 'hidden', 'pc:flex')}>
            <Link href={env.LIVING_SITE + createPath(ROUTE_MAP.HOME, {}, { withLocale: true })}>
              <div className="w-18 h-6.5 cursor-pointer">
                <DImage className="w-full h-full" src={IMG_LIVING_LOGO} />
              </div>
            </Link>
            <div
              className={classNames('h-3 border-l border-ahaa-border-gray mx-4 hidden', 'pc:block')}
            />
            {/* 站点选择框 */}
            {/* <Select
              align={{ points: ['br'], offset: [30, 10] }}
              selectClassName="bg-transparent border-none text-white px-0 text-xs"
              selectItemClassName="h-11 w-35.5 justify-center border-black-6"
              arrowClassName={classNames('text-white ml-1')}
              itemSelectedBgColor={true}
              value={SiteList[2]}
              options={SiteList}
              onChange={selectSite}
            />
            <div
              className={classNames('h-3 border-l border-ahaa-border-gray mx-4 hidden', 'pc:block')}
            /> */}
            {/* 语言选择框 */}
            <Select
              align={{ points: ['br'], offset: [30, 10] }}
              selectClassName="bg-transparent border-none text-white px-0 text-xs"
              selectItemClassName={classNames(
                'h-11 w-35.5 justify-center border-black-6 bg-black-8 text-black'
              )}
              arrowClassName={classNames('text-white ml-1')}
              itemSelectedBgColor={true}
              value={language}
              options={LANGUAGE_OPTIONS}
              onChange={selectLanguage}
            />
          </div>
          {!isRNWebViewState && (
            <>
              <div className={classNames('flex pc:hidden')}>
                <Select
                  align={{ points: ['br'], offset: [30, 10] }}
                  selectClassName="bg-transparent border-none text-white px-0 text-xs"
                  selectItemClassName={classNames(
                    'h-11 w-35.5 justify-center border-black-6 bg-black-8 text-black'
                  )}
                  arrowClassName={classNames('text-white ml-1')}
                  itemSelectedBgColor={true}
                  value={language}
                  options={LANGUAGE_OPTIONS}
                  onChange={selectLanguage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
