/*
 * @Author: 朱璐
 * @Date: 2022-11-10 14:22:09
 * @Description: 常规带搜索分类请求头和底部导航的layout
 */
import { downLoadNoticeId } from '@common/components/common/download-notice';
import { Sticky } from '@common/components/common/sticky';
import { useSize } from 'ahooks';

import { Footer } from '@/components/common/footer';
import type { HeaderProps } from '@/components/common/header';
import { Header } from '@/components/common/header';
import { MobileBackHeader } from '@/components/common/mobile-back-header';

import { noticeHeaderId } from './header/header-notice';
// import { LanguageChange } from './language-header/language-change';

export interface Props extends HeaderProps {
  /** 存在backTitle 移动端显示返回头而不是搜索头 */
  backTitle?: string;
  /** 返回头返回路径 默认返回上一页 */
  backPath?: string;
  /** 背景颜色 */
  bgColor?: string;
  /** 是否展示移动端HEAD搜索区域 */
  showHeadSearch?: boolean;
  footerClassName?: string;
  floatingButtonClassName?: string;
  rightRender?: React.ReactNode;
}

export const CustomLayout: React.FC<React.PropsWithChildren<Props>> = ({
  backTitle,
  backPath,
  children,
  destroyCategory,
  showUser = true,
  bgColor = 'bg-black-8',
  footerClassName,
  floatingButtonClassName,
  rightRender,
  showHeadSearch,
  ...headerProps
}) => {
  const { height: noticeHeaderHeight } =
    useSize(() => document.getElementById(noticeHeaderId)) ?? {};
  const { height: downloadNoticeHeight } =
    useSize(() => document.getElementById(downLoadNoticeId)) ?? {};

  // 计算 totalHeight
  let totalHeight = 0;

  if (noticeHeaderHeight) {
    totalHeight = -noticeHeaderHeight;

    if (downloadNoticeHeight) {
      totalHeight = downloadNoticeHeight - noticeHeaderHeight;
    }
  }

  return (
    <>
      <div className="w-full min-h-screen flex flex-col items-center">
        <Sticky className="flex-shrink-0 w-full" id="page-header" offset={totalHeight} wait={0}>
          <Header
            showUser={showUser}
            destroyCategory={destroyCategory}
            showHeadSearch={showHeadSearch}
            {...headerProps}
          >
            {backTitle && (
              <MobileBackHeader title={backTitle} path={backPath} rightRender={rightRender} />
            )}
          </Header>
        </Sticky>

        <div className="flex-1 w-full flex flex-col">
          <section className={`flex-1 flex flex-col ${bgColor}`}>{children}</section>
          {/* <div className="container py-6 pad:hidden bg-white">
            <LanguageChange
              className={
                'bg-white h-12 rounded-md border border-solid border-black-4 px-2 justify-between'
              }
            />
          </div> */}
          <Footer className={footerClassName} floatingButtonClassName={floatingButtonClassName} />
        </div>
      </div>
    </>
  );
};
