/* eslint-disable global-require */
/*
 * @Author: 朱璐
 * @Date: 2022-10-28 11:17:24
 * @Description: common图片
 */

export const IMG_MAINTENANCE = require('@/resources/images/maintenance.png');
export const IMG_LOADING = require('@/resources/images/loading.gif');
export const IMG_LOGO = require('@/resources/images/logo.png');
export const IMG_MENU = require('@/resources/images/icon-menu.png');
export const IMG_MENU_CLOSE = require('@/resources/images/menu-close.png');
export const IMG_ICON_TO_TOP = require('@/resources/images/icon-top.png');
export const IMG_PRODUCT = require('@/resources/images/product-image.png');
export const IMG_GOOD_CARD_TAG = require('@/resources/images/good-card-tag.png');
export const IMG_STORES_EMPTY = require('@/resources/images/stores-empty.png');
export const IMG_ICON_NETWORK = require('@/resources/images/icon-network.png');
export const IMG_ICON_NOT_FOUND = require('@/resources/images/icon-not-found.png');
export const IMG_ICON_ORDER = require('@/resources/images/icon-order.png');
export const IMG_ICON_NOT_ADDRESS = require('@/resources/images/icon-not-address.png');
export const IMG_ICON_NOT_COUPON = require('@/resources/images/icon-not-coupon.png');
export const IMG_STORES_HEADER_BG = require('@/resources/images/stores-header-bg.png');
export const IMG_STORES_HEADER_RIGHT_BG = require('@/resources/images/stores-header-right-bg.png');
export const IMG_STORES_RED_CAR = require('@/resources/images/store-car.png');
export const IMG_ICON_NOT_POINTS = require('@/resources/images/icon-not-points.png');
export const IMG_ICON_NOT_COLLECT = require('@/resources/images/icon-not-collect.png');
export const IMG_ICON_COMMON_EMPTY = require('@/resources/images/icon-common-empty.png');
export const ICON_PROMOTION_LABEL_LEFT = require('@/resources/images/icon-promotion-label-left.png');
export const IMG_ICON_NO_EVALUATION = require('@/resources/images/icon-no-evaluation.png');
export const IMG_NO_DATA = require('@/resources/images/no-data.png');
export const IMG_ICON_LOADING = require('@/resources/images/icon-loading.png');
export const ICON_EMPTY_CART = require('@/resources/images/empty-cart.png');
export const ICON_ADDRESS_TOP_BORDER = require('@/resources/images/icon-address-top-border.png');
export const ICON_NO_PRODUCT = require('@/resources/images/icon-no-product.png');
export const ICON_PROMOTION_LABEL = require('@/resources/images/icon-promotion-label.png');
export const IMG_COUPON_LOGO = require('@/resources/images/icon-coupon-logo.png');
export const ACCOUNT_POINT_BG = require('@/resources/images/account-point-bg.png');
export const ACCOUNT_VOUCHERS_BG = require('@/resources/images/account-vouchers-bg.png');
export const IMG_COUPON_BG = require('@/resources/images/img-coupon-bg.png');
export const IMG_COUPON_INVALID_BG = require('@/resources/images/img-coupon-invalid.png');
export const IMG_FREIGHT_BG = require('@/resources/images/img-freight-bg.png');
export const IMG_CASH_COUPON_BG = require('@/resources/images/img-cash-coupon-bg.png');
export const IMG_SELECT_BG = require('@/resources/images/img-select.png');
export const ICON_PROMOTION_HIGHLIGHT = require('@/resources/images/icon-promotion-highlight.png');
export const IMG_FOOTER = require('@/resources/images/footer-bg.png');
export const IMG_FOOTER_MOBILE = require('@/resources/images/footer-bg-mobile.png');
export const IMG_AHHA_PRODUCT = require('@/resources/images/img-ahaa-product.png');
export const IMG_CHINA_BANK = require('@/resources/images/img-china-bank.png');
export const IMG_QR_CODE = require('@/resources/images/qr-code.png');
export const IMG_LIVING_LOGO = require('@/resources/images/living-logo.png');
export const IMG_DOWNLOAD_NOTICE = require('@/resources/images/download-logo.png');
